<template>
  <v-container fluid>
    <Header headerClass="politiche" title="Politiche per l'aria" />
    <v-container page id="content">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Inquinamento e stili di vita</h2>
          <div class="w-80">
            <p class="mt-40">
              Migliorare l’aria delle nostre città non significa solo ridurre
              l'inquinamento atmosferico, ma anche
              <strong
                >ripensare i nostri modi di spostarci, consumare e
                mangiare</strong
              >.
            </p>
          </div>
          <div class="mt-40 stili1">
            <p>
              <strong class="color">Un milione</strong> di spostamenti al giorno
              potrebbe essere effettuato a piedi in meno di 10 minuti
            </p>
          </div>
          <div class="stili2">
            <p>
              <strong class="color">6,47 milioni</strong> di spostamenti
              quotidiani su veicoli a motore potrebbero essere effettuati in
              bicicletta in meno di 20 minuti
            </p>
          </div>
          <div class="stili3">
            <p>
              Il programma School Street elimina dalle strade
              <strong class="color">4.000 autovetture</strong> durante le ore di
              punta
            </p>
          </div>
          <div class="stili4">
            <p>
              Una pista ciclabile a scorrimento veloce riduce il tempo trascorso
              nelle strade congestionate di
              <strong class="color">3.8 milioni</strong> di ora
            </p>
          </div>
          <div class="stili5">
            <p>
              Una rete di piste ciclabili a scorrimento veloce rende superflui
              <strong class="color">50.000 spostamenti</strong> in auto ogni
              giorno
            </p>
          </div>
          <div class="stili6">
            <p>
              Un programma di bike-sharing snellisce
              <strong class="color">la congestione stradale</strong> durante i
              lavori in città
            </p>
          </div>
          <div class="stili7">
            <p>
              Le migliorie alle infrastrutture pedonali riducono del
              <strong class="color">40%</strong> i tempi di percorrenza degli
              autobus
            </p>
          </div>
          <div class="stili8">
            <p>
              Nuove piazze pedonali riducono del
              <strong class="color">15%</strong> i tampi di spostamento di taxi
              e autobus
            </p>
          </div>
          <p class="mt-40">
            Fonte:
            <a
              href="http://h2020-flow.eu/resources/publications/"
              target="_blank"
              title="Questo link si apre in una nuova scheda"
              >progetto H2020 FLOW http://h2020-flow.eu/resources/publications/
              <i class="fas fa-external-link-alt" aria-hidden="true"></i
            ></a>
          </p>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuPolitiche page="stili-vita" />
        </v-col>
      </v-row>
    </v-container>

    <div class="stili-vita"></div>

    <v-container>
      <v-row>
        <v-col class="col-md-9 col-12">
          <v-card flat class="mt-40">
            <v-list-item three-line>
              <v-list-item-content>
                <p>Alcune <strong>buone pratiche:</strong></p>
                <div class="lista">
                  <ul class="mt-40">
                    <li>
                      utilizza il trasporto pubblico per ridurre la congestione
                      urbana
                    </li>
                    <li>
                      per gli spostamenti brevi considera di andare in
                      bicicletta o camminare
                    </li>
                    <li>
                      richiedi alle autorità locali Zone a Traffico Limitato o
                      aree completamente pedonalizzate nei pressi di aree
                      sensibili come scuole, ospedali, asili nido e centri per
                      anziani
                    </li>
                    <li>
                      considera di sostituire il tuo veicolo con un’auto a
                      emissioni ridotte
                    </li>
                    <li>
                      crea un car-pool comunitario per i tragitti casa-scuola o
                      casa-lavoro
                    </li>
                    <li>riduci i consumi energetici in casa e al lavoro</li>
                    <li>consuma cibo a km zero e riduci il consumo di carne</li>
                    <li>
                      organizza un gruppo locale per realizzare campagne di aria
                      pulita in città.
                    </li>
                  </ul>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuPolitiche from "@/views/politiche/MenuPolitiche";

export default {
  components: {
    Header,
    MenuPolitiche
  }
};
</script>
