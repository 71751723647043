<template>
  <div :class="{ menu_fixed: scrolled }">
    <div>
      <h3>POLITICHE PER L'ARIA</h3>
      <v-list class="menu-dx">
        <v-list-item>
          <router-link :to="{ name: 'piano' }">
            Piano Regionale di Qualità dell'Aria
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'misure' }"> Misure di piano </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'progetti' }"> Progetti </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'reti' }">
            Reti di collaborazione
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'stili' }">
            Inquinamento e stili di vita
          </router-link>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'misure-piano' || page == 'piano-regionale'">
      <div v-if="page == 'piano-regionale'">
        <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
        <v-list class="pt-0">
          <v-list-item>
            <!--a href="/politiche/misure"> Misure di piano </a-->
            <router-link :to="{ name: 'misure' }">
              Misure di piano
            </router-link>
          </v-list-item>
        </v-list>
      </div>
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="https://www.regione.piemonte.it/web/temi/ambiente-territorio/ambiente/aria/piano-regionale-qualita-dellaria-prqa"
            target="_blank"
          >
            PRQA Regione Piemonte
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'progetti-regione'">
      <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
      <v-list class="pt-0">
        <v-list-item>
          <!--a href="/politiche/reti"> Reti di collaborazione </a-->
          <router-link :to="{ name: 'reti' }">
            Reti di collaborazione
          </router-link>
        </v-list-item>
      </v-list>
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a href="https://www.lifeprepair.eu" target="_blank">
            https://www.lifeprepair.eu
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'reti-collaborazione'">
      <h3>APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a href="https://airregions.wordpress.com/" target="_blank">
            AIR - Air quality Initiative of Regions
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="https://www.under2coalition.org/" target="_blank">
            Under2 coalition
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="https://www.alpine-region.eu/" target="_blank">
            EU Strategy for the Alpine region
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="https://egtc-rhine-alpine.eu/it/" target="_blank">
            Gruppo Europeo di Coop. Territoriale
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'stili-vita'">
      <h3>APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="http://h2020-flow.eu/resources/publications/"
            target="_blank"
          >
            Progetto H2020 FLOW
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  props: ["page"],
  data() {
    return {
      scrolled: false
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>
